<template>
  <v-layout column>
    <FieldHeader
      v-if="!hideHeader"
      :field="field"
      :patient="patient"
      :session.sync="session"
      :owner="owner"
      :hideCollapseBtn="hideCollapseBtn"
      :hideDuplicate="hideDuplicate"
      :collapsed="hideBody"
      :name="name"
      @history="onHistory"
      @collapse="onCollapse"
    >
      <slot></slot>
    </FieldHeader>
    <v-layout column v-if="!disabled && !hideBody">
      <HTMLTextArea :value.sync="text" placeholder="Nowy wpis..." dense>
        <v-layout align-center slot="toolbar">
          <AutosaveIcon class="mr-1" :value="status"></AutosaveIcon>
          <PredefinedRecordMenu
            v-if="field.predefined_records_enabled"
            :field="field"
            :clientOnly="clientOnlyPredefined"
            :cardType="session.session_type"
            @append="onPredefined"
            @append:inline="onPredefinedInline"
          ></PredefinedRecordMenu>
        </v-layout>
      </HTMLTextArea>
      <v-layout
        v-if="field.name == 'Recommendation' && field.recommendations && field.recommendations.length"
        class="mx-3"
      >
        <v-layout column>
          <div class="scale-title">Automatyczne propozycje:</div>
          <v-layout
            align-center
            v-for="(rec, index) in field.recommendations"
            :key="index"
            class="clickable-row"
            @click.prevent="() => onPredefinedInline([rec])"
          >
            <v-icon class="default-icon mt-1">fas fa-plus</v-icon>
            <div class="scale mt-2">{{ rec }}</div>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :session="session"
      :hideEdit="hideEdit"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
      @copy="onCopy"
    >
      <div
        v-html="text || '-'"
        class="text-body-1 pch-text my-2 black--text"
      ></div>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideDuplicate: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    text: {
      get() {
        if (!this.field.data) {
          return "";
        } else {
          return this.field.data.text;
        }
      },
      set(value) {
        this.$set(this.field.data, "text", value);
      },
    },
  },
  components: {
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
    PredefinedRecordMenu: () =>
      import("@/components/menus/PredefinedRecordMenu"),
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    HTMLTextArea: () => import("@/components/inputs/HTMLTextArea"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onPredefined(data) {
      this.$emit("predefined", data);
    },
    onPredefinedInline(data) {
      data.forEach((record) => {
        this.text += `<p>${record}</p>`;
      });
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onCopy() {
      this.$emit("copy");
    }
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      this.startWatching("text");
    }
  },
};
</script>

<style lang="scss" scoped>
.pch-text-wrapper {
  border-radius: 15px;
  background-color: white;
}
.pch-text {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.pch-text ::v-deep p {
  margin-bottom: 0 !important;
}
.default-shadow {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
.scale-title {
  font-weight: bold !important;
  font-size: 13px !important;
  height: 18px !important;
}
.scale {
  font-size: 12px !important;
  line-height: 18px !important;
  color: black !important;
  &:hover {
    background-color: #eeeeee;
  }
}
.clickable-row {
  cursor: pointer;
}
.default-icon {
  margin-top: 7px !important;
  margin-right: 3px !important;
  font-size: 11px !important;
  min-width: 16px !important;
  height: 16px !important;
  border-radius: 50px !important;
  color: white !important;
  background-color: var(--v-expressive_green-base) !important;
}
</style>